
import { Component, Input, EventEmitter, Output , ViewEncapsulation, HostListener, ElementRef} from "@angular/core";
import { MultiSelectOptionItemModel } from "src/app/modules/isolated.components/dropdown/model";
import { NgFor } from "@angular/common";
import { KeyValuePair } from "src/app/infrastructure/types";

@Component({
    selector: 'multi-select-dropdown',
    templateUrl: './multi-select-dropdown.component.html',
    styleUrls: ['./multi-select-dropdown.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor]
})
export class MultiSelectDropDownComponent {

    @Input() classes: string;
    @Input() placeholder:string = "";
    @Input() selectedValueAsValues:boolean = false;
    @Input() selectedValueAsKeys:boolean = false;
    @Input() showNumberOfSelectedOptions:boolean = false;
    
    @Output() selectedOptionsChange: EventEmitter<any> = new EventEmitter<any>();


    _options:Array<MultiSelectOptionItemModel> = new Array<MultiSelectOptionItemModel>();
    _selectedValues:any[]=[];

    showOptions:boolean = false;

    

    @Input() set options(value:any){
      this._options = value;

      if (this._selectedValues){
        this.setOptionsToSelectedValues(this._selectedValues);
      }


    }

    get options(){
      return this._options;
    }


    get selectedValues():any{
      var valuesArray:KeyValuePair<any, any>[] = [];
      var selectedArrayValues:any[] = [];
      var selectedArrayKeys:any[] = [];

      if (this.options && this.options.length>0){
        this.options.filter(p=>p.isSelected==true).forEach(element => {
          if (this.selectedValueAsValues){
            selectedArrayValues.push(element.value);
          }
          else if (this.selectedValueAsKeys){
            selectedArrayKeys.push(element.key);
          }
          else{
            valuesArray.push(element);
          }
        });
      }
      if (this.selectedValueAsValues){
        return selectedArrayValues;
      }

      if (this.selectedValueAsKeys){
        return selectedArrayKeys;
      }

      return valuesArray;  
        
    }

    @Output() selectedValuesChange = new EventEmitter<any>();

    @Input() set selectedValues(value:any){

      this._selectedValues = value;

      this.setOptionsToSelectedValues(value);
    }

  private setOptionsToSelectedValues(value: any) {
    if (!value || value.length == 0) {
      return;
    }
    
    var optionsValues = this.options;

    optionsValues.forEach(element => {
      element.isSelected = false;
    });

    if (value) {
      if (value && value.length > 0) {
        value.forEach(element => {

          if (this.selectedValueAsKeys || this.selectedValueAsValues) {
            var optionToSet = optionsValues.find(p => p.key == element);
            if (optionToSet){
              optionToSet.isSelected = true;
            }
          }
          else{
            var optionToSet = optionsValues.find(p => p.key == element.key);
            if (optionToSet){
              optionToSet.isSelected = true;
            }
          }

          
        });
      }
    }
  }



    onItemSelected(item){
      if (item.isSelected){
        item.isSelected=!item.isSelected;
      }
      else{
        item.isSelected = true;
      }

      this.selectedValuesChange.emit(this.selectedValues);


    }

    get selectedOptions(){

      var selectedItems = [];
      var numberOfSelectedItems = 0;

      if (this.options && this.options.length>0){
        this.options.filter(p=>p.isSelected==true).forEach(element => {
          selectedItems.push(element.value);
          numberOfSelectedItems++;
       });

       if (this.showNumberOfSelectedOptions){
        if (numberOfSelectedItems>0){
          return this.placeholder + " (" + numberOfSelectedItems.toString() + ")";
        }
        else{
          return this.placeholder;
        }
        }

       if (selectedItems.length>0){
        return selectedItems.join(", ").toString();
       }
      }
      
    

     return this.placeholder;


  }

    @HostListener('document:click', ['$event'])
    clickout(event) {
      if(!this.eRef.nativeElement.contains(event.target)) {
        this.showOptions = false;
      }
    }


    onShowDropdown(){
      this.showOptions = !this.showOptions;
    }


    constructor(private eRef: ElementRef) {

    }

}