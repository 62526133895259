<div class="multi-select-dropdown {{classes}}">
    <div class="select" (click)="this.onShowDropdown()">
      <span>{{this.showOptions?this.placeholder:this.selectedOptions}}</span>
    </div>
    <div class="selection" [style.display]="this.showOptions?'block':'none'">
      <ul>
        <li *ngFor="let item of this.options">
            <input type="checkbox" [checked]="item.isSelected" (change)="onItemSelected(item)"/>
            <span>{{item.value}}</span>
        </li>
      </ul>
    </div>
</div>
